<template>
  <div class="flightbooking_form_rightbody">
    <div class="heading">
      <h3>
        <div class="d-flex align-items-center">
          <span>
            <img src="/assets/sabre-img/airplane2.png" alt="airplane"/>
          </span>
          <strong>{{$t("sabre.booking.ticket-box.select-card-type")}}</strong>
        </div>
      </h3>
    </div>
    <div class="flightbooking_form_middel">
      <div class="pricingtable_area">
        <div class="row g-2">
          <div class="col-lg-3">
            <pricing-table-left-area :commercials="commercials"/>
          </div>
          <div class="col-lg-9">
            <pricing-carousel v-model="sldTicketTypeIndex" :commercials="commercials" :noSelect="noSelect"/>
          </div>
        </div>
      </div>
    </div>
    <div class="flightbooking_form_footer">
      <p v-if="sldTicketTypeIndex !== -1">{{ selectedTicketName }} - {{ $t('sabre.booking.order-detail-box.total')}} {{ totalPerson }}
        <strong>${{sltDiffPrice}} </strong>
      </p>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ticket-type-selection-form',
  components: {
    PricingTableLeftArea: () => import('./pricingTableLeftArea'),
    PricingCarousel: () => import('./pricingCarousel'),
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    initTicket: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    ...mapGetters({
      orderedFlight: 'GET_SABRE_ORDERED_FLIGHT',
      brandFeatureDescs: 'GET_SABRE_BOOKING_BRAND_DESC',
      isLoading: 'GET_LOADING_STATE',
    }),
    commercials() {
      const { orderedFlight, brandFeatureDescs } = this;

      // grouped the features by same name
      const groupedBrandFeature = brandFeatureDescs.reduce((acc, cur) => {
        if (!acc[cur.commercialName]) {
          acc[cur.commercialName] = [];
        }
        acc[cur.commercialName].push(cur.id);
        return acc;
      }, {});

      // extract all feature list from pricing information
      const duplicatedList = [...new Set(orderedFlight.pricingInformation.map((brand) => brand.fare.brandFeatures).flat())];

      // extract keys that contain the feature id
      const unDuplicatedKeys = Object.keys(groupedBrandFeature).filter((key) => groupedBrandFeature[key].some((elem) => duplicatedList.includes(elem)));

      // remove from object items that doesn't contain
      Object.keys(groupedBrandFeature).forEach((key) => {
        if (!unDuplicatedKeys.includes(key)) delete groupedBrandFeature[key];
      });
      return groupedBrandFeature;
    },
    minimalPrice() {
      const { orderedFlight } = this;
      return orderedFlight?.pricingInformation[0]?.fare?.totalFare?.totalPrice || 0;
    },
    sltDiffPrice() {
      const { sldTicketTypeIndex, orderedFlight, minimalPrice } = this;
      if (sldTicketTypeIndex === -1) return 0;
      const sltPrice = orderedFlight?.pricingInformation[sldTicketTypeIndex]?.fare?.totalFare?.totalPrice || 0;
      return Math.round(sltPrice - minimalPrice);
    },
    selectedTicketName() {
      const { sldTicketTypeIndex, orderedFlight } = this;
      if (sldTicketTypeIndex === -1) return '';
      return orderedFlight?.pricingInformation[sldTicketTypeIndex]?.fare?.brand?.brandName || '';
    },
    totalPerson() {
      const { sldTicketTypeIndex, orderedFlight } = this;
      if (sldTicketTypeIndex === -1) return 2;
      return orderedFlight?.pricingInformation[sldTicketTypeIndex]?.fare?.passengerInfoList[0]?.passengerNumber || 2;
    },
  },
  watch: {
    sldTicketTypeIndex() {
      this.$emit('update', { type: 'ticketType', data: this.sldTicketTypeIndex });
    },
    isLoading() {
      this.noSelect = this.isLoading;
    },
  },
  data() {
    return {
      sldTicketTypeIndex: -1,
      noSelect: false,
    };
  },
  mounted() {
    this.sldTicketTypeIndex = this.initTicket;
  },
};
</script>

<style scoped>
/* .flightbooking_form_middel{
  max-height: 500px;
  overflow-y: auto;
} */
</style>
